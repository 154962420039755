$FontPathOpenSans: '../../fonts/' !default;

@font-face {
    font-family: 'Handlee';
    font-weight: normal;
    font-style: normal;
    src: url('#{$FontPathOpenSans}/handlee-regular-webfont.woff') format('woff');
}

@font-face {
    font-family: 'Magra-Regular';
    font-weight: normal;
    font-style: normal;
    src: url('#{$FontPathOpenSans}/magra-regular-webfont.woff') format('woff');
}
@font-face {
    font-family: 'Magra-Bold';
    font-weight: normal;
    font-style: normal;
    src: url('#{$FontPathOpenSans}/magra-bold-webfont.woff') format('woff');
}

@font-face {
    font-family: 'DroidSerif';
    font-weight: normal;
    font-style: normal;
    src: url('#{$FontPathOpenSans}/DroidSerif-webfont.woff') format('woff');
}

@font-face {
    font-family: 'DroidSerif-Bold';
    font-weight: normal;
    font-style: normal;
    src: url('#{$FontPathOpenSans}/DroidSerif-Bold-webfont.woff') format('woff');
}
