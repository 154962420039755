$FontPathRoboto: "../../fonts/Roboto" !default;


@font-face {
    font-family: 'Roboto-Regular';
    font-style: normal;
    src: url('#{$FontPathRoboto}/Roboto-Regular.woff') format('woff')
  }


  @font-face {
    font-family: 'Roboto-Italic';
    font-style: italic;
    src: url('#{$FontPathRoboto}/Roboto-Italic.woff') format('woff')
  }

  @font-face {
    font-family: 'Roboto-Bold';
    font-style: normal;
    src: url('#{$FontPathRoboto}/Roboto-Bold.woff') format('woff')
  }

  @font-face {
    font-family: 'Roboto-Medium';
    font-style: normal;
    src: url('#{$FontPathRoboto}/Roboto-Medium.woff') format('woff')
  }

  @font-face {
    font-family: 'Roboto-Light';
    font-style: normal;
    src: url('#{$FontPathRoboto}/Roboto-Light.woff') format('woff')
  }


@font-face {
    font-family: 'RobotoSlab-Regular';
    font-style: normal;
    src: url('#{$FontPathRoboto}/RobotoSlab-Regular.woff') format('woff')
  }

  @font-face {
    font-family: 'RobotoSlab-Bold';
    font-style: normal;
    src: url('#{$FontPathRoboto}/RobotoSlab-Bold.woff') format('woff')
  }

  @font-face {
    font-family: 'RobotoSlab-Light';
    font-style: normal;
    src: url('#{$FontPathRoboto}/RobotoSlab-Light.woff') format('woff')
  }

  @font-face {
    font-family: 'RobotoSlab-Medium';
    font-style: normal;
    src: url('#{$FontPathRoboto}/RobotoSlab-Medium.woff') format('woff')
  }
