* {
    box-sizing: border-box;
}

::-webkit-input-placeholder {
    opacity: 1;
    transition: opacity 0.3s;
}

::-moz-placeholder {
    opacity: 1;
    transition: opacity 0.3s;
}

/* FF 19+ */

:-ms-input-placeholder {
    opacity: 1;
    transition: opacity 0.3s;
}

/* IE 10+ */

*:focus::-webkit-input-placeholder {
    opacity: 0;
}

*:focus::-moz-placeholder {
    opacity: 0;
}

/* FF 19+ */

*:focus:-ms-input-placeholder {
    opacity: 0;
}

/* IE 10+ */

ul {
    margin: 0;
    padding: 0
}

body,
input,
textarea,
button {
    font-family: "Open Sans", Roboto, "Helvetica Neue", sans-serif;
    font-size: 16px;
}

html,
body {
    margin: 0;
    padding: 0;
    line-height: 1.2;
}

button {
    border: 0;
}

.hide {
    display: none !important;
}

.center {
    text-align: center;
}

.left {
    float: left;
}

.right {
    float: right;
}

.clear {
    clear: both;
}

bp-dropdown.title input {
    font-size: 1.1em;
}

h4,
h5,
h6 {
    font-weight: normal;
    font-size: 1.1em;
    padding: 10 20;
    margin: 0;
    margin-bottom: 5px;
    color: #000;
}

h1,
h2,
h3,
input.h1 {
    font-weight: 400;
    font-size: 1.25em;
    margin: 0;
    padding: 0;
    color: #000;
}

h1.hero {
    display: block;
    margin: 0;
    padding: 0 1em;
    height: 57px;
    line-height: 57px;
    font-size: 21px !important;
    color: #000;
    border-bottom: 1px solid #F3F3F3;

    i {
        font-size: 18px;
    }
}

input.h1 {
    border: 1px solid transparent;
    border-bottom: 1px solid #ccc;
    width: 95%;
}

input.h1:hover {
    border-color: #ccc;
    border-width: 1px 1px 1px 5px;
}

input.h1:focus {
    border-color: #56ACD4;
    border-width: 1px 1px 1px 5px;
}

ul {
    padding: 0;
    list-style: none;
}

.flex {
    display: flex;

    &.column {
        flex-flow: column;
    }
}
.grow {
    flex-grow: 1;
}
.flex-right {
    margin-left: auto;
}

.flow {
    flex-flow: wrap row;
}


.tar {
    background-color: #FC0E2E !important;
}

.sch {
    background-color: #40B54F !important;
}

.fin {
    background-color: #000000 !important;
}

.action {
    cursor: pointer;
}

.row-action {
    color: #000;
    margin: 0.2em 1em;
    font-size: 1.6em;
    cursor: pointer;
}

.gu-mirror {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.mat-select-value {
    padding-left: .4em;
}

/* Sliding Tabs */

/* messages */

.mat-simple-snackbar-message {
    font-size: 1.1em;
}

snack-bar-container.warn {
    border-radius: 1em 1em 0 0;
    padding-bottom: .5em;
    background: rgb(96, 108, 136);
    background: linear-gradient(135deg, rgba(96, 108, 136, 1) 0%, rgba(63, 76, 107, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#606c88', endColorstr='#3f4c6b', GradientType=1);
}

snack-bar-container.info {
    background: #003982;
    border-radius: 1em 1em 0 0;
    padding-bottom: .5em;
    background: #003982;
    background: linear-gradient(135deg, #003982 0%, #2989d8 50%, #207cca 51%, #7db9e8 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e5799', endColorstr='#7db9e8', GradientType=1);
}

snack-bar-container.error {
    background: #9d0000;
    border-radius: 1em 1em 0 0;
    padding-bottom: .5em;
    background: #a90329;
    background: linear-gradient(135deg, #a90329 0%, #8f0222 44%, #6d0019 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a90329', endColorstr='#6d0019', GradientType=1);
}

@keyframes fade-in {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

.fade-in-1s {
    animation-name: fade-in;
    animation-duration: 1s;
}

.fade-in {
    animation-name: fade-in;
    animation-duration: .2s;
}

@keyframes fade-in-left {
    0% {
        transform: translate3d(-100%, 0, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

.fade-in-left {
    animation-name: fade-in-left;
    animation-duration: .3s;
}

@keyframes fade-in-top {
    0% {
        transform: translate3d(0, -100%, 0);
        opacity: 0
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

.fade-in-top {
    animation-name: fade-in-top;
    animation-duration: .3s;
}

.blur {
    filter: blur(3px);
}

/* BUTTONS */

button.bp,
a.bp,
.bp li {
    transition: background 200ms, color 200ms;
    cursor: pointer;
    margin: 0;
    padding: .5em .75em .4em .75em;
    color: #007CBE;
    background: transparent;
    border-radius: .2em;
    display: block;
}

.bp.inline {
    display: inline-block;
    border-color: transparent;
}

.bp.inline li,
a.bp.inline {
    display: inline-block;
}

button.bp i,
a.bp i {
    color: #56ACD4;
    margin: 5px;
}

button.bp:hover,
a.bp:hover,
.bp li:hover {
    background: #D9EEFD;
    color: #003371;
    text-decoration: none;
}

button.bp:hover>i,
a.bp:hover>i {
    color: #56ACD4;
}

button.bp:disabled,
button.bp:disabled:hover {
    cursor: not-allowed;
    opacity: .5;
}

a.hero,
button.hero {
    background: #007CBE;
    color: #fff;

    i {
        color: #fff;
    }

    &:hover {
        background: #006399;
        color: #fff;

        i {
            color: #fff;
        }
    }
}

a {
    cursor: pointer;
    color: #2C7EBF;
    text-decoration: none;

    &:hover {
        color: #000;
        text-decoration: none;
    }
}

ul.bp {
    margin: 2em;
}

ul.bp li {
    padding: .5em;
    border: 1px solid transparent;
    font-size: 1.1em;
    cursor: pointer;
    transition: background, border-color .3s;
}

.drag-handle {
    cursor: move;
    cursor: grab;
}

.gu-mirror {
    list-style: none;
}

.card {
    background: #fff;
    max-width: 500px;
    box-shadow: 0px 0px 5px #808080;
    margin: 4em auto;

    &>h1 {
        background: #f2f2f2;
        border: 1px solid #E6E6E6;
        margin: 0;
        padding: 15px 10px;
    }

    &>.content,
    &>form {
        display: block;
        padding: 2em;
    }
}

.not-visible {
    visibility: hidden;
}

.hidden {
    display: none !important;
}

a.blade-link {
    background: #FF7700;
    border: 0 none;
    color: #fff;
    font-size: 15px;
    text-decoration: none;
    position: relative;
    transition: all .3s;
    display: inline-block;
    line-height: 1.6;
    border-radius: 5px;
    box-shadow: 0 1px 3px #777;
    padding: 6px;
    font-weight: 600;

    &:hover {
        background: #b35300;
        text-decoration: none;
    }
}

.warn,
.warn input {
    color: brown;
    border-color: brown !important;
}

.cdk-overlay-pane div.mat-tooltip {
    font-size: 14px !important;
    line-height: 1.2;
}

i.action {
    cursor: pointer;
    opacity: .5;
    transition: opacity .2s;

    &:hover {
        opacity: 1;
    }
}

.modal {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
}

.bp-dialog-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .2);
    animation: fade-in .2s;

    .dialog {
        background-color: #fff;
        outline: 0;
        box-shadow: 0 3px 7px rgba(0, 0, 0, .3);
        padding: 15px 20px;
        border-top: 10px solid #007DBF;
        border-radius: 10px;

        &>h1 {
            margin: 0;
            padding-top: 0;
            border-bottom: 1px solid #007DBF;
            font-size: 24px;
            font-weight: 400;
        }
    }
}

.bp-sliding-dialog-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .2);
    animation: fade-in .2s;
    flex-direction: column;

    .dialog {
        background-color: #fff;
        outline: 0;
        box-shadow: 0 3px 7px rgba(0, 0, 0, .3);
        padding: 15px 20px;
        border-radius: 10px;
        width: 50em;
        z-index: 10;

        &>h1 {
            margin: 0;
            padding-top: 0;
            border-bottom: 1px solid #007DBF;
            font-size: 24px;
            font-weight: 400;
        }
    }

    .sliding-dialog {
        background-color: #fff;
        outline: 0;
        box-shadow: 0 3px 7px rgba(0, 0, 0, .3);
        padding: 15px 20px;
        border-radius: 10px;
        width: 48em;
        margin-top: -10px;
        z-index: 1;
        background: #e6e6e6;

        &>h1 {
            margin: 0;
            padding-top: 0;
            border-bottom: 1px solid #007DBF;
            font-size: 24px;
            font-weight: 400;
        }
    }
}

.bp-alert-dialog-wrapper {
    position: fixed;
    top: 0;
    z-index: 2;
    right: 0;
    animation: fade-in .2s;
}

.normal {
    font-weight: 400;
}

input.bp {
    border: 1px solid #E5E5E5;
    border-radius: .3em;
    padding: .3em;
    margin: .3em 0;
}

.bold {
    font-weight: 800;
}

.show-xs,
.show-xxs {
    display: none;
}

.splash-content {
    display:block;
    min-height: calc(100vh - 350px);
    margin: 0 auto;
    max-width: 1250px;
    padding-bottom: 1em;
}

/* @media screen and (max-width: 1300px) {

    body,
    input,
    textarea,
    button {
        font-size: 13px;
    }

    .show-xs {
        display: inline;
    }

    .hide-xs {
        display: none !important;
    }

    .fa-lg {
        font-size: 1em;
    }
}

@media screen and (max-width: 450px) {
    .hide-xxs {
        display: none !important;
    }

    .show-xxs {
        display: inline;
    }
} */
aim-loading {
    background: rgba(255,255,255,.5);
}