@font-face {
    font-family: 'aim-icons';
    src: url('../../fonts/aim-icons.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
    font-family: 'aim-icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-logo:before {
    content: '\e901';
}
.icon-brainstorm:before {
    content: '\e907';
}
.icon-objectives:before {
    content: '\e908';
}
.icon-testing:before {
    content: '\e909';
}
.icon-market:before {
    content: '\e902';
}
.icon-discovery:before {
    content: '\e903';
}
.icon-preference:before {
    content: '\e904';
}
.icon-business-case:before {
    content: '\e905';
}
.icon-job-aids:before {
    content: '\e906';
}
.icon-cloak:before {
    content: '\e900';
}
.icon-maximize:before {
    content: '\e90a';
}
.icon-minimize:before {
    content: '\e90b';
}

.icon-large-notes:before {
    content: "\e90c";
}

.icon-large-notes-selected:before {
    content: "\e90d";
}

.icon-small-notes:before {
    content: "\e90e";
}

.icon-small-notes-selected:before {
    content: "\e90f";
}

.icon-move-back:before {
    content: "\e910";
}

.icon-move-bottom:before {
    content: "\e911";
}

.icon-move-top:before {
    content: "\e912";
}

.icon-thumbs-down-subtract:before {
    content: "\e913";
}

.icon-thumbs-up-add:before {
    content: "\e914";
}

.icon-no-selected:before {
    content: "\e915";
}

.icon-no:before {
    content: "\e916";
}

.icon-unsure-selected:before {
    content: "\e917";
}

.icon-unsure:before {
    content: "\e918";
}

.icon-yes-selected:before {
    content: "\e919";
}

.icon-yes:before {
    content: "\e91a";
}
